<template>

  <div class="xxmain">
    <div class="infomation"  @click="imagesPerview">
      <div class="info_title">
        <p class="keys">国（境）外学历学位认证书</p>
        <van-button style="float: right;"  color="#51A938" type="primary" size="mini" @click="imagesPerview">查看证照</van-button>
      </div>
      <div class="info_box">
        <div class="info_list">
          <p class="keys">证照颁发机构</p>
          <p class="values">教育部留学服务中心</p>
        </div>
        <div class="info_list">
          <p class="keys">证照编号</p>
          <p class="values">120230069370</p>
        </div>
        <div class="info_list">
          <p class="keys">证照颁发日期</p>
          <p class="values">2023-03-29</p>
        </div>
        <div class="info_list">
          <p class="keys">证照来源</p>
          <p class="values">教育部留学服务中心</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {

  data() {
    return {

    };
  },

  methods:{
    imagesPerview() {
      ImagePreview({
        images: [ require('@/assets/image/zz.png')],
        closeable: true
      });
    }
  }
};
</script>

<style scoped>
.xxmain {
  padding: 13px;
}

.infomation {
  width: 346px;
  padding: 15px;
  border-radius: 10px;
  filter: drop-shadow(0 0 10.5px rgba(118,118,118,.16));
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.info_title {
  display: flex;
  justify-content: space-between;
}

.info_title .keys {
  font-size: 17px;
  letter-spacing: -2px;
  line-height: 21px;
  color: #000;
  font-weight: 600;
  font-family: Source Han Sans CN;
}

.info_title .values {
  font-size: 13px;
  color: #ff9f00;
  font-family: Source Han Sans CN;
}

.info_box {
  margin-top: 21px;
}

.info_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.info_list .keys,.info_list .values {
  font-size: 14px;
  line-height: 21px;
  color: #666;
  font-family: Source Han Sans CN;
  word-break: break-all;
}

</style>
