// 路由-相关模块
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index'
import Home from '@/views/Home'
import Zwfw from '@/views/Zwfw'
import Zxkf from '@/views/Zxkf'
import Jybd from '@/views/Jybd'
import Lxcd from "@/views/Lxcd";
import Gppc from "@/views/Gppc";
import Smhy from "@/views/Smhy";
import Grlz from "@/views/Grlz";
import Grzx from "@/views/Grzx";
import Xlxwrz from "@/views/Xlxwrz";
import Zwfwjdcx from "@/views/Zwfwjdcx";
import Grxx from "@/views/Grxx";
import Gywm from "@/views/Gywm";

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        component: Index,
        redirect: '/index/home',
        children: [
            {
                path: 'home',
                component: Home,
                meta: { // meta保存路由对象额外信息的
                    title: "首页"
                }
            },
            {
                path: 'zwfw',
                component: Zwfw,
                meta: {
                    title: "政务服务"
                }
            },
            {
                path: 'zxkf',
                component: Zxkf,
                meta: {
                    title: "在线客服"
                }
            },
            {
                path: 'grzx',
                component: Grzx,
                meta: {
                    title: "个人中心"
                }
            }
        ]
    },
    {
        path: '/jybd',
        component: Jybd
    },
    {
        path: '/lxcd',
        component: Lxcd
    },
    {
        path: '/gppc',
        component: Gppc
    },
    {
        path: '/smhy',
        component: Smhy
    },
    {
        path: '/grlz',
        component: Grlz
    },
    {
        path: '/xlxwrz',
        component: Xlxwrz
    },
    {
        path: '/zwfwjdcx',
        component: Zwfwjdcx
    },
    {
        path: '/grxx',
        component: Grxx
    },
    {
        path: '/gywm',
        component: Gywm
    },
]

const router = new VueRouter({
    routes
})


export default router