<template>

  <div >
   <div>
      <img :src="require('@/assets/image/title_lf.png')" class="layui-circle" style="width: 100%;">
    </div>
    <div class="mui-card-content">
      <ul class="mui-table-view">
        <li class="mui-table-view-cell">
          <div style="font-size:20px;" align="center">国（境）外学历学位认证书</div>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-bookmark-o"> 证照名称</i></h5><h5>国外学历学位认证书</h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-barcode"> 证照编号</i></h5><h5>120230069370</h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-user-o"> 持证者</i></h5><h5>吴经纬</h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-id-card-o"> 持证者类型</i></h5><h5>身份证</h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-address-card-o"> 持证者代码</i></h5><h5>420106199612198453</h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-calendar-plus-o"> 起始有效期</i></h5><h5></h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-calendar-minus-o"> 截至有效期</i></h5><h5>长期</h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-bank"> 颁发部门</i></h5><h5>教育部留学服务中心</h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-calendar-check-o"> 颁发日期</i></h5><h5>2023-03-29</h5>
        </li>
        <li class="mui-table-view-cell">
          <h5 class="green"><i class="fa fa-check-square-o"> 证照状态</i></h5><h5>有效</h5>
        </li>
      </ul>

    </div>

  </div>
</template>
import 'font-awesome/css/font-awesome.min.css';
<script>

export default {
  data() {
    return {};
  },

};
</script>

<style scoped>
.mui-card-content {
  font-size: 14px;
  position: relative;
}
.mui-card .mui-table-view {
  margin-bottom: 0;
  border-top: 0;
  border-bottom: 0;
  border-radius: 6px;
}

.mui-table-view {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  background-color: #fff;
}
.mui-table-view:before {
  position: absolute;
  right: 0;
  left: 0;
  height: 1px;
  content: '';
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  background-color: #c8c7cc;
  top: -1px;
}
.green {
  color: #0060AA;
}

.mui-h5, h5 {
  font-size: 14px;
  font-weight: 400;
  color: #8f8f94;
}
.mui-table-view-cell:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 15px;
  height: 1px;
  content: '';
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
  background-color: #c8c7cc;
}
.mui-table-view-cell{
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 11px 15px;
  -webkit-touch-callout: none;
}

.mui-table-view i {
  margin-bottom: 5px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


</style>
