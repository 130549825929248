<template>
  <div class="personal_box">
    <div class="infomation">
      <div class="info_list">
        <p class="keys">头像</p>
        <img class="avatar" src="@/assets/image/useravatar.png" style="width: 35px;" >
      </div>
      <div class="info_list">
        <p class="keys">姓名</p>
        <p class="values">吴经纬</p>
      </div>
      <div class="info_list">
        <p class="keys">性别</p>
        <p class="values">男</p>
      </div>
      <div class="info_list">
        <p class="keys">证件类型</p>
        <p class="values">身份证</p>
      </div>
      <div class="info_list">
        <p class="keys">证件号</p>
        <p class="values">420106199612198453</p>
      </div>
      <div class="info_list">
        <p class="keys">生日</p>
        <p class="values">1996-12-19</p>
      </div>
      <div class="info_list">
        <p class="keys">国籍/地区</p>
        <p class="values">中国</p>
      </div>
      <div class="info_list">
        <p class="keys">手机号</p>
        <p class="values">18086681219</p>
      </div>
      <div class="info_list">
        <p class="keys">邮箱</p>
        <p class="values">820274725@qq.com</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.personal_box {
  padding: 13px;
}

.infomation {
  width: 346px;
  padding: 15px 15px 0px;
  border-radius: 10px;
  filter: drop-shadow(0 0 10.5px rgba(118,118,118,.16));
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.info_title {
  display: flex;
  justify-content: space-between;
}

.info_title .keys {
  font-size: 17px;
  letter-spacing: -0.5px;
  line-height: 21px;
  color: #000;
  font-weight: 600;
  font-family: Source Han Sans CN;
}

.info_title .values {
  font-size: 13px;
  color: #ff9f00;
  font-family: Source Han Sans CN;
}

.info_box {
  margin-top: 21px;
}

.info_list {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #eee;
}

.info_list .keys,.info_list .values {
  font-size: 14px;
  line-height: 21px;
}

.info_list .values {
  color: #666;
  font-family: Source Han Sans CN;
}
</style>