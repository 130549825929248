<template>

  <div class="xxmain">

      <div class="content_box">
        <div class="header_top">
          <img src="@/assets/image/service/jybd.png" style="width:20px;">
          <p>就业报到</p>
        </div>
        <div class="title_box">
          <div class="rectangle"></div>
          <p class="title">办理结果查验</p>
        </div>
        <p class="content">
          由我中心开具的《就业报到证》、《落户介绍信》及《中外合作办学毕业生就业报到证明信》统一使用标准版式文档格式的电子证照，其真伪查询方式有以下三种：
        </p>
        <p class="content" style="word-break:break-all;">
          <span >1.登录留学政务服务平台（http://zwfwbl.cscse.edu.cn/homeView/contactUs?type=6）进行在线查验；
          </span>
        </p>
        <p class="content">
          2.关注“教育部留学服务中心”官方微信公众号，在“办理查询-就业报到”栏目下进行查验；
        </p>
        <p class="content">
          3.使用“教育部留学服务中心”官方微信小程序的“扫码验证”功能进行查验。
        </p>
      </div>


  </div>
</template>

<script>

export default {
  data() {
    return {};
  },

};
</script>

<style scoped>
.xxmain {
  padding: 13px;
}

.content_box {
  width: 345px;
  padding: 15px;
  border-radius: 10px;
  filter: drop-shadow(0 0 10.5px rgba(118,118,118,.16));
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.header_top {
  display: flex;
  height: 35px;
  align-items: center;

}

.header_top img {
  margin-right: 15px;
}

.header_top p {
  font-size: 15px;
  letter-spacing: -2px;
  line-height: 25px;
  color: #000;
  font-weight: 600;
}

.rectangle {
  display: inline-block;
  width: 3px;
  height: 12.5px;
  border-radius: 1.5px;
  background-color: #ffdc28;
  margin-right: 10px;
}

.title_box {
  display: flex;
  align-items: center;
  font-size: 15px;
  letter-spacing: -0.5px;
  height: 45px;
  color: #000;
  font-weight: 600;
}

.content {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  word-break: break-all;
}

.content_center {
  font-size: 16px;
  line-height: 35px;
  color: #fd9a16;
  font-weight: 600;
}
</style>