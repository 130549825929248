<template>
  <div style="  background: #f5f5f5;
  padding: 0 0 60px 14px;">

    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="image in images" :key="image">
        <img class="nes_image" :src="image"/>
      </van-swipe-item>
    </van-swipe>


    <div class="contents">
      <span class="title">电子证照核验</span>
      <div class="list">
        <div class="listItem" @click="openFn('/smhy')">
          <img alt="" class="img" src="@/assets/image/home/xueli.png">
          <span class="text">学位信息</span>
        </div>
        <div class="listItem" @click="openFn('/grlz')">
          <img alt="" class="img" src="@/assets/image/home/jylh.png">
          <span class="text">个人亮证</span>
        </div>
      </div>
    </div>

    <div class="contents" @click="alertDialog">
      <span class="title">出国前</span>
      <div class="list">
        <div class="listItem">
          <img alt="" class="img" src="@/assets/image/liuxue.png">
          <span class="text">平安留学培训</span>
        </div>
        <div class="listItem">
          <img alt="" class="img" src="@/assets/image/home/yzzy.png">
          <span class="text">海外优质教育资源</span>
        </div>
      </div>
    </div>

    <div class="contents" @click="alertDialog">
      <span class="title">在国外</span>
      <div class="list">
        <div class="listItem">
          <img alt="" class="img" src="@/assets/image/home/hdbm.png">
          <span class="text">在外活动报名</span>
        </div>
      </div>
    </div>

    <div class="contents" @click="alertDialog">
      <span class="title">回国后</span>
      <div class="list">
        <div class="listItem">
          <img alt="" class="img" src="@/assets/image/home/job.png">
          <span class="text">云招聘</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Dialog} from "vant";

export default {

  data() {
    return {
      show:false,
      images: [
        require('@/assets/image/home/homeimg.png'),
      ]
    };
  },
  methods:{
    alertDialog(){
      Dialog({
        title: '提示',
        message: '此功能暂未上线,敬请期待',
      }).then(() => {
        // on close
      });
    },
    openFn(key){
      this.$router.push({
        path: key,
        query: {
        }
      })
    }
  },

  components: {
    [Dialog.Component.name]: Dialog.Component
  }
};
</script>

<style scoped>

.nes_image {
  height: 150px;
}

.my-swipe {
  padding: 5px 0px;
  border-radius: 10px;
  height: 150px;
  /*  background-color: #39a9ed;*/
}

.contents {
  position: relative;
  width: 345px;
  height: 153px;
  background: #fff;
}

.contents .title {
  display: block;
  font-size: 14px;
  height: 45px;
  line-height: 45px;
  padding-left: 12px;
  background: #f9f9f9;
}

.contents .list {
  border-radius: 10px;
  display: flex;
  width: 345px;
  height: 110px;
  background: #fff;
}

.contents .list .listItem {
  width: 115px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.contents .list .listItem .img {
  width: 42px;
  height: 42px;
  margin: 12.5px auto;
}

.contents .list .listItem .text {
  font-size: 12px;
}

</style>