<template>
  <div class="body">
    <div class="content">
      <div class="serve_list" @click="openFn('/xlxwrz')">
        <div class="left_box">
          <img class="left_icon" src="@/assets/image/user/xwxl.png">
          <p class="serve_text">学历学位认证</p>
        </div>
        <img class="right_icon" src="@/assets/image/user/icon_back.png">
      </div>
      <div class="serve_list"  @click="openFn('/jybd')">
        <div class="left_box">
          <img class="left_icon" src="@/assets/image/user/jybd.png">
          <p class="serve_text">就业报到</p>
        </div>
        <img class="right_icon" src="@/assets/image/user/icon_back.png">
      </div>
      <div class="serve_list"  @click="openFn('/lxcd')">
        <div class="left_box">
          <img class="left_icon" src="@/assets/image/user/lxcd.png">
          <p class="serve_text">留学存档</p>
        </div>
        <img class="right_icon" src="@/assets/image/user/icon_back.png">
      </div>
      <div class="serve_list"  @click="openFn('/gppc')">
        <div class="left_box">
          <img class="left_icon" src="@/assets/image/user/gppc.png">
          <p class="serve_text">公派派出</p>
        </div>
        <img class="right_icon" src="@/assets/image/user/icon_back.png">
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "index",
  methods: {
    openFn(key) {
      this.$router.push({
        path: key,
        query: {}
      })
    }
  }
}

</script>

<style scoped>
.body {
  padding: 13px;
}

.content {
  width: 346px;
  padding: 0px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  filter: drop-shadow(0 0 10.5px rgba(118,118,118,.16));
  background-color: #fff;
}

.serve_list {
  justify-content: space-between;
  height: 67px;
  line-height: 67px;
  border-bottom: 0.5px solid #eee;
}

.left_box,.serve_list {
  display: flex;
  align-items: center;
}

.left_box p {
  font-size: 15px;
  letter-spacing: -0.5px;
  color: #000;
  font-family: Source Han Sans CN;
}

.left_icon {
  width: 20px;
  margin-right: 20px;
}

.right_icon {
  width: 10.5px;
}
</style>