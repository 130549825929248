<template>

  <div class="user_info_wrap">
    <div class="user_img_wrap">
      <img class="bgImg" src="@/assets/image/bg.png">
      <div class="user_info">
        <img class="user_icon" src="@/assets/image/useravatar.png">
        <p class="user_name">吴经纬</p>
      </div>
    </div>
    <div class="content_wrapper">
      <div class="list">
        <div class="list_item" @click="openFn('/zwfwjdcx')">
          <img class="icon" src="@/assets/image/search.png">
          <p class="list_info">政务服务进度查询</p>
          <img class="more_icon" src="@/assets/image/back.png">
        </div>
        <div class="list_item" @click="openFn('/grxx')">
          <img class="icon" src="@/assets/image/users.png">
          <p class="list_info">个人信息</p>
          <img class="more_icon" src="@/assets/image/back.png">
        </div>
        <div class="list_item" @click="openFn('/grlz')">
          <img class="icon" src="@/assets/image/license1.png">
          <p class="list_info">我的证照</p>
          <img class="more_icon" src="@/assets/image/back.png">
        </div>
        <div class="list_item" @click="openFn('/gywm')">
          <img class="icon" src="@/assets/image/info.png">
          <p class="list_info">关于我们</p>
          <img class="more_icon" src="@/assets/image/back.png">
        </div>
<!--        <div bindtap="goLogin" class="list_item">
          <img class="icon" src="@/assets/image/login1.png">
          <p bindtap="Logout" class="list_info">登录</p>
          <img class="more_icon" src="@/assets/image/back.png">
        </div>-->
        <div bindtap="Logout" class="list_item" >
          <img  class="icon" src="@/assets/image/off.png">
          <p bindtap="Logout" class="list_info">注销</p>
          <img class="more_icon"  src="@/assets/image/back.png">
        </div>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  data() {
    return {
      value: "", // 搜索关键词

    };
  },

  methods: {
    openFn(key) {
      this.$router.push({
        path: key,
        query: {}
      })
    }
  }
};
</script>

<style scoped>
.user_info_wrap {
  background: #ccc;
  height: 100%;
}

.user_img_wrap {
  width: 100vw;
  height: 132px;
  overflow: hidden;
  position: relative;
}

.bgImg {
  width: 118%;
  height: 132px;
  position: absolute;
  z-index: 1;
}

.user_info {
  width: 100%;
  height: 100%;
  padding-left: 21.5px;
  padding-top: 25px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
}

.user_icon {
  width: 55.5px;
  height: 55.5px;
  border-radius: 50%;
}

.user_name {
  color: #fff;
  font-size: 16.5px;
  margin-left: 12px;
}

.user_btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40%;
  border: 0.5px solid #3479f6;
  color: #3479f6;
  font-size: 19px;
  padding: 15px;
  border-radius: 5px;
}

.content_wrapper {
  height: 100%;
  background: #f5f5f5;
  padding: 14px;
  box-sizing: border-box;
}

.content_wrapper .list {
  width: 100%;
}

.list_item {
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  background: #fff;
  border-bottom: 0.5px solid #ececed;
  position: relative;
}

.list_item:first-child {
  border-radius: 14px 14px 0 0;
}

.list_item:last-child {
  border-radius: 0 0 14px 14px;
}

.list_item:nth-child(1) .icon {
  width: 17.5px;
  height: 19.5px;
}

.list_item:nth-child(2) .icon {
  width: 20px;
  height: 15.5px;
}

.list_item:nth-child(3) .icon {
  width: 18px;
  height: 15.5px;
}

.list_item:nth-child(4) .icon, .list_item:nth-child(5) .icon, .list_item:nth-child(6) .icon {
  width: 19px;
  height: 19px;
}

.mgs_num {
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16.5px;
  border-radius: 50%;
  color: #fff;
  font-size: 9.5px;
  background-color: #fa5151;
  position: absolute;
  top: 25%;
  left: 28%;
}

.more_icon {
  width: 6px;
  height: 11px;
  position: absolute;
  right: 13px;
}

.list_info {
  display: block;
  height: 22px;
  line-height: 22px;
  margin-left: 12px;
  font-family: SourceHanSansCN-Regular;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
}

.un_login {
  background: transparent;
}

.unload_bgImg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
}

.topbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 44px;
  line-height: 44px;
  background: #f5f5f5;
  z-index: 99;
}

</style>