<template>
  <div style="  background: #f5f5f5;
  padding: 0 0 60px 14px;">
    <div class="serve_list" @click="openFn('/xlxwrz')">
      <img class="serve_img" src="@/assets/image/home/xlxw.png">
      <span class="serve_text">学历学位认证</span>
    </div>

    <div class="serve_list" @click="openFn('/jybd')">
      <img class="serve_img" src="@/assets/image/home/jybd.png">
      <span class="serve_text">就业报到</span>
    </div>

    <div class="serve_list" @click="openFn('/lxcd')">
      <img class="serve_img" src="@/assets/image/home/lxcd.png">
      <span class="serve_text">留学存档</span>
    </div>

    <div class="serve_list" @click="openFn('/gppc')">
      <img class="serve_img" src="@/assets/image/home/gppc.png">
      <span class="serve_text">公派派出</span>
    </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "", // 搜索关键词
      hotArr: [], // 热搜关键字
      resultList: [], // 搜索结果
      loading: false, // 加载中 (状态) - 只有为false, 才能触底后自动触发onload方法
      finished: false, // 未加载全部 (如果设置为true, 底部就不会再次执行onload, 代表全部加载完成)
      page: 1, // 当前搜索结果的页码
      timer: null // 输入框-防抖定时器
    };
  },

  methods: {
    openFn(key) {
      this.$router.push({
        path: key,
        query: {}
      })
    }
  }
};
</script>

<style scoped>

.serve_list {
  width: 100%;
  height: 90px;
  padding-left: 28px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  background: #fff;
  margin-bottom: 15px;
}

.serve_img {
  width: 54px;
  height: 54px;
}

.serve_text {
  display: block;
  margin-left: 17px;
  width: 100px;
  font-size: 14px;
}

</style>