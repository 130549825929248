<template>
  <div >
    <!-- 一级路由-显示地方 -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {

}
</script>

<style>

</style>