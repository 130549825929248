<template>

  <div class="xxmain">

    <div class="content_box">
      <div class="header_top">
        <img src="@/assets/image/user/gppc.png" style="width:20px;">
        <p>公派派出</p>
      </div>
      <div class="title_box">
        <div class="rectangle"></div>
        <p class="title">电子报到证说明</p>
      </div>
      <p class="content">
        留学人员在收到电子客票行程单后的5个工作日以内，即可登录留学政务服务平台，点击右上方“用户中心”，根据个人需要，自行打印电子报到证。</p>
      <p class="content" style="word-break:break-all;">
        系统生成电子报到证后，会自动发送邮件和短信通知，请在收到邮件或短信后登录留学政务服务平台进行查看。
      </p>
      <p class="content_center">
        温馨提示:
      </p>
      <p class="content">
        1.疫情期间，因航班变动较为频繁，我处将会在航班起飞后进行系统的处理，系统处理后才能生成电子报到证。因此，有可能会超出上述“5个工作日”的时限，敬请谅解。
      </p>
      <p class="content">
        2.如尚未与基金委办理完结协议书相关事宜，电子报到证将无法生成，直至上述事宜审核通过，基金委将派出意见推送至我处系统，才能生成电子报到证。
      </p>
      <p class="content">
        3.电子报到证为图片格式，如需保存至本地，请在电子报到证上长按即可保存。
      </p>
      <p class="content">
        4.电子报到证样例见下：
      </p>
      <img src="https://wlf.cscse.edu.cn/api/dfs/profile//2021/07/30/25d022c6f1d88a8d5e3902ac048e449d.png" style="width:100%;">
    </div>


  </div>
</template>

<script>

export default {
  data() {
    return {};
  },

};
</script>

<style scoped>
.xxmain {
  padding: 13px;
}

.content_box {
  width: 345px;
  padding: 15px;
  border-radius: 10px;
  filter: drop-shadow(0 0 10.5px rgba(118, 118, 118, .16));
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.header_top {
  display: flex;
  height: 35px;
  align-items: center;

}

.header_top img {
  margin-right: 15px;
}

.header_top p {
  font-size: 15px;
  letter-spacing: -2px;
  line-height: 25px;
  color: #000;
  font-weight: 600;
}

.rectangle {
  display: inline-block;
  width: 3px;
  height: 12.5px;
  border-radius: 1.5px;
  background-color: #ffdc28;
  margin-right: 10px;
}

.title_box {
  display: flex;
  align-items: center;
  font-size: 15px;
  letter-spacing: -0.5px;
  height: 45px;
  color: #000;
  font-weight: 600;
}

.content {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  word-break: break-all;
}

.content_center {
  font-size: 16px;
  line-height: 35px;
  color: #fd9a16;
  font-weight: 600;
}

</style>