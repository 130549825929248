<template>
  <div style="   padding: 13px;">
    <div class="infomation">
      <div class="info_title">
        <p class="keys">基础信息</p>
        <p class="values">认证完成</p>
      </div>
      <div class="info_box">
        <div class="info_list">
          <p class="keys">姓名</p>
          <p class="values">吴经纬</p>
        </div>
        <div class="info_list">
          <p class="keys">认证类型</p>
          <p class="values">正式认证</p>
        </div>
        <div class="info_list">
          <p class="keys">申请类型</p>
          <p class="values">国(境)外</p>
        </div>
        <div class="info_list">
          <p class="keys">学校/项目名称</p>
          <p class="values">迈阿密大学(俄亥俄州)</p>
        </div>
        <div class="info_list">
          <p class="keys">认证学位</p>
          <p class="values">工商管理学学士学位</p>
        </div>
        <div class="info_list">
          <p class="keys">认证状态</p>
          <p class="values">认证完成</p>
        </div>
        <div class="info_list">
          <p class="keys">申请时间</p>
          <p class="values">2023-03-23 12:39:52</p>
        </div>
        <div class="info_list">
          <p class="keys">操作</p>
          <p class="values">正常</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.infomation {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  filter: drop-shadow(0 0 10.5px rgba(118,118,118,.16));
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.info_title {
  display: flex;
  justify-content: space-between;
}

.info_title .keys {
  font-size: 17px;
  letter-spacing: -0.5px;
  line-height: 21px;
  color: #000;
  font-weight: 600;
  font-family: Source Han Sans CN;
}

.info_title .values {
  font-size: 13px;
  color: #ff9f00;
  font-family: Source Han Sans CN;
}

.info_box {
  margin-top: 21px;
}

.info_list {
  display: flex;
  justify-content: space-between;
}

.info_list .keys,.info_list .values {
  font-size: 14px;
  line-height: 21px;
  color: #666;
  font-family: Source Han Sans CN;
}

</style>
