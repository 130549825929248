<template>
  <div class="about_box">
    <img class="images" src="@/assets/image/us.png">
    <p>
      中国中国留学服务中心，是教育部直属事业单位，以事业单位法人注册，主要从事出国留学、留学回国和来华留学以及教育国际交流与合作的有关服务，与国内外相关机构建立了良好的业务合作关系。
    </p>
    <p>
      服务宗旨：服务全球学子，成就国际人才
    </p>
    <p>
      服务准则：快速、准确、热情、周到
    </p>
    <p>
      地 址：北京市海淀区北四环西路56号辉煌时代大厦 6层
    </p>
    <p>
      邮政编码：100080
    </p>
    <p>
      综合咨询：010-62677800
    </p>
  </div>


</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.about_box {
  width: 100vw;
  min-height: 100vh;
  background: #fff;
}

.about_box img {
  width: 375px;
  height: 154.5px;
}

.about_box p {
  padding: 22px 36px 0 27px;
  font-family: SourceHanSansCN-Regular;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  line-height: 20px;
  color: #000;
  opacity: .8;
}
</style>