<template>
  <div>
    <!--    <van-nav-bar :title="activeTitle" fixed />-->
    <div>
      <!-- 二级路由-挂载点 -->
      <router-view></router-view>
    </div>

    <van-tabbar v-model="active" active-color="#F9C600" >
      <van-tabbar-item :to="(item.name)" @click="tabIndex(index)" v-for="(item,index) in tabbars"
                       :key="'tabbar' + index">
        <span>{{ item.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.active : item.normal"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>

  </div>
</template>

<script>


export default {
  name: "indexTab",
  data() {
    return {
      active: 0, //默认选中tab
      tabbars: [
        {
          name: "/index/home",
          title: "首页",
          normal: require("@/assets/image/home2.png"),
          active: require("@/assets/image/home.png")
        },
        {
          name: "/index/zwfw",
          title: "政务服务",
          normal: require("@/assets/image/zwfw2.png"),
          active: require("@/assets/image/zwfw.png")
        },
        {
          name: "/index/zxkf",
          title: "联系我们",
          normal: require("@/assets/image/service.png"),
          active: require("@/assets/image/serviceing.png")
        },
        {
          name: "/index/grzx",
          title: "个人中心",
          normal: require("@/assets/image/user2.png"),
          active: require("@/assets/image/user.png")
        },
      ]
    };
  },
  mounted() {
    // 如有缓存，刷新页面时还进入该页面
    let index = sessionStorage.getItem('tabIndex')
    if (index) {
      this.tabIndex(index)
    }
  },
  methods: {
    tabIndex(index) {
      index = Number(index)
      this.active = index;
      // 记录当前tab页
      sessionStorage.setItem('tabIndex', index)
      let val = this.tabbars[index].name
      this.$router.push(val);
    },
  },
};
</script>

<style scoped>
/* 中间内容区域 - 容器样式(留好上下导航所占位置) */
.main {
  background: #f5f5f5;
  padding: 0 0 60px 14px;
}

.van-tabbar-item {
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
}

.van-tabbar-item__icon img {
  display: block;
  height: 20px;
}

</style>