<template>
  <div style="  padding: 13px 15px 60px 14px;
  background: #f5f5f5;">

    <p style="text-align:center;font-size:17px;font-weight:600 ">联系我们</p>
    <p class="text">自2012年3月21日（星期三）起，教育部留学服务中心已经迁至新的办公地址对外办公。</p>
    <p class="text">教育部留学服务中心新址：北京市海淀区北四环西路56号辉煌时代大厦 6层</p>
    <p class="text">邮政编码：100080</p>
    <p class="text">综合咨询：010-62677800。</p>
    <p class="text">留学回国人员需要提供海外学历、学位与认证咨询，请查阅：<span
        class="text">学历学位认证邮件咨询须知。</span></p>
    <p class="text">有关公派留学人员申办出国手续办理事宜咨询，请电咨询：010-62677800。</p>
    <p class="text">留学人员有关派遣落户新系统请咨询邮箱：<br><span class="text">jylh@cscse.edu.cn</span>（如为系统技术问题，建议附上页面截图）。
    </p>
    <p class="text">自费出国留学人员办理档案保存等，咨询邮件详见：<span class="text">档案邮件咨询须知。</span></p>
    <p class="text">
      “春晖杯”中国留学人员创业创业大赛事宜咨询邮箱：<span class="text">cyds@cscse.edu.cn</span> ;春晖计划短期回国服务项目、合作科研咨询邮箱：
      <span class="text">dqtz@cscse.edu.cn</span>
      （短期回国服务）、 <span class="text">hzky@cscse.edu.cn</span> （合作科研）；高层次海外留学人才身份证明咨询邮箱： <span
        class="text">chunhui@cscse.edu.cn</span>
    </p>
    <p class="text">来华留学人员咨询，请发往： <br><span class="text">laihua@cscse.edu.cn。</span></p>
    <p class="text">
      寻求与中国留学网进行网上留学服务、高层次人才技术服务、远程教育、远程合作研究以及电子商务推广，请发往：<span
        class="text">support@cscse.edu.cn。</span>
    </p>
    <p class="text">国际交流与合作项目、教育展览及论坛，请致电客服：01062677800</p>
    <p class="text">其它自费留学事宜，请发往：<br><span class="text">info@chivast.cscse.org</span></p>
    <p class="text">教育部留学服务中心网站：中国留学网<br><span class="text">www.cscse.edu.cn</span></p>

  </div>
</template>

<script>

export default {
  data() {
    return {};
  },

};
</script>

<style scoped>

.text {
  margin: 10px 0px;
  word-break: break-all;
  font-size: 15px;
}

.text .text {
  color: #1800cc;
}
</style>